import React from 'react'

import Footer from './Footer'
import avatar from '../assets/images/avatar.jpg'

const Header = () => (
  <header id="header">
    <div className="inner">
      <div className="image avatar">
        <img src={avatar} alt="" />
      </div>
      <h1>
        Hi, <strong>I am Song</strong>, 
        <br />
        a full stack web and mobile developer
        <br />
      </h1>
    </div>
    <Footer />
  </header>
)

export default Header
