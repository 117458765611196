import React from 'react'

const Footer = () => (
  <div id="footer">
    <div className="inner">
      <ul className="icons">
        <li>
          <a href="https://github.com/songchen1999" className="icon fa-github">
            <span className="label">Github</span>
          </a>
        </li>
        <li>
          <a href="https://www.linkedin.com/in/song-chen-a7240011a/" className="icon fa-linkedin">
            <span className="label">linkedin</span>
          </a>
        </li>
        <li>
          <a href="mailto:songchen@songchen.space" className="icon fa-envelope-o">
            <span className="label">Email</span>
          </a>
        </li>
      </ul>
      <ul className="copyright">
        <li>&copy; Song Chen</li>
      </ul>
    </div>
  </div>
)

export default Footer
