import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

const HomeIndex = () => {
  const siteTitle = 'Song Chen'
  const siteDescription = 'personal website of Song Chen'

  return (
    <Layout>
      <Helmet>
        <title>{siteTitle}</title>
        <meta name="description" content={siteDescription} />
      </Helmet>

      <div id="main">
        <section id="one">
          <header className="major">
            <h2>
              <br/>
              Current Compsci Major Student
              <br />
              @ UMass Amherst
            </h2>
          </header>
          <p>
          I am experienced in frameworks like React, React Native, Node (Express) and Flask, databases including MongoDB, Postgres, and Firebase. 
          <br/>
          I am familiar with algorithms, Agile, HCI, and team management.
          <br/>
          Outside of tech, I also work as an undergraduate teaching assistant and a peer mentor to give back to the community.
          </p>
          <ul className="actions">
            <li>
              <a href="https://www.linkedin.com/in/song-chen-a7240011a/" className="button">
                Learn More
              </a>
            </li>
          </ul>
        </section>

        <section id="three">
          <h2>Get In Touch</h2>
          <p>
            Send me an email or connect me on linkedin
          </p>
          <div className="row">
            <div className="4u 12u$(small)">
              <ul className="labeled-icons">
                <li>
                  <h3 className="icon fa-envelope-o">
                    <span className="label">Email</span>
                  </h3>
                  <a href="mailto:songchen@songchen.space">songchen@songchen.space</a>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default HomeIndex
